import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import { MdEmail, MdPortrait, MdMessage } from "react-icons/md"
import SEO from "../components/SEO"
// import "../templates/materializeForms"
import "../components/Contact.css"

export default ({ data }) => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = data

  const [formState, setFormState] = React.useState({
    name: "",
    email: "",
    message: "",
  })
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))
    setFormState({
      name: "",
      email: "",
      message: "",
    })

    e.preventDefault()
  }
  return (
    <Layout>
      <SEO title="Contact" description="Send us recipes" />
      <Hero height="40vh" title="Contact" desc="get in touch" fluid={fluid} />
      <section className="blog-page row">
        <div className="col s12 m5 l6 offset-l3 form-container center-align">
          <h4 className="title teal-text">Send Us Your Recipes!</h4>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="input-field">
              <MdPortrait className="prefix"></MdPortrait>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                value={formState.name}
              />
              <label
                htmlFor="name"
                className={`${formState.name != "" ? "active" : ""}`}
              >
                Your Name
              </label>
            </div>
            <div className="input-field">
              <MdEmail className="prefix"></MdEmail>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                value={formState.email}
              />
              <label
                htmlFor="email"
                className={`${formState.email != "" ? "active" : ""}`}
              >
                Your Email
              </label>
            </div>
            <div className="input-field">
              <MdMessage className="prefix"></MdMessage>
              <textarea
                name="message"
                id="message"
                className="materialize-textarea"
                onChange={handleChange}
                value={formState.message}
              ></textarea>
              <label
                htmlFor="message"
                className={`${formState.message != "" ? "active" : ""}`}
              >
                Message
              </label>
            </div>
            <div className="input-field center">
              <button className="btn" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "aux1.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
